
import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let adminInfo;
  if (Cookies.get('adminInfo')) {
    adminInfo = JSON.parse(Cookies.get('adminInfo'));
    config.data = { mobileNumber: adminInfo.mobileNumber, merchantToken: adminInfo.token, ...config.data };
  }
  return {
    ...config,
    headers: {
      // authorization: adminInfo ? `Bearer ${adminInfo.token}` : null,
      merchantToken: adminInfo ? adminInfo.token : null
    },
  };
});


// Add a response interceptor
instance.interceptors.response.use(response => {
  // console.log("response : ", response);
  return response.data;
}, function (error) {
  // console.log("error: ", error.response);
  if (error.response.status === 401) {
    localStorage.clear();
  }
  return { succes: false, status: error.response.status, message: error.response.data?.message || `Request has been failed due to ${error.response.status}` };
})


const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers),

  post: (url, body) => {
    console.log(`${process.env.REACT_APP_API_BASE_URL} ${url}`);
    return instance.post(url, body);
  },

  put: (url, body, headers) =>
    instance.put(url, body, headers),

  patch: (url, body) => instance.patch(url, body),

  delete: (url) => instance.delete(url),
};

export default requests;


// import axios from "axios";
// import RequestInterceptor from "./request_interceptor";
// import ResponseInterceptor from "./response_interceptor";

// // This will configure the singleton axios instance
// const API = axios
// .create({
//   baseURL: 'https://store.wroti.app/',
//   headers: {'X-Custom-Header': 'foobar'},
//   "Access-Control-Allow-Origin": "*",
// });

// API.interceptors.request.use(RequestInterceptor);
// API.interceptors.response.use(undefined, ResponseInterceptor);

// export default API;
